/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
    font-family: "Roca One";
    src: url(./fonts/Roca\ Regular.ttf);
}

@font-face {
    font-family: "The Seasons";
    src: url(./fonts/Fontspring-DEMO-theseasons-reg.otf);
}

@font-face {
    font-family: "The Seasons Italic";
    src: url(./fonts/Fontspring-DEMO-theseasons-ltit.otf);
} */

:root {
    --primary-100: #326298;
    --primary-200: #809ec8;
    --primary-300: #f0e0e3;
    --primary-400: #f4b2ac;
    --primary-500: #f3ede1;
    --neutrals-100: #000000;
    --neutrals-200: #1a1a1a;
    --neutrals-300: #333333;
    --neutrals-400: #4d4d4d;
    --neutrals-500: #666666;
    --neutrals-600: #999999;
    --neutrals-700: #cccccc;
    --neutrals-800: #ffffff;
    --title-ff: "Roca One";
    --heading-ff: "The Seasons";
    --body-ff: "Montserrat", sans-serif;
    --h1-size: 8rem;
    --h2-size: 6.1rem;
    --h3-size: 4.7rem;
    --h4-size: 3.6rem;
    --h5-size: 2.7rem;
    --h6-size: 2.1rem;
    --body-size-lg: 1.6rem;
    --body-size-md: 1.2rem;
    --body-size-sm: 0.9rem;
    --line-height: 150%;
}

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    line-height: var(--line-height);
}

h1,
h2 {
    font-family: var(--title-ff);
}

h3,
h4,
h5,
h6 {
    font-family: var(--heading-ff);
}

p {
    font-family: var(--body-ff);
}

.interests-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.ellipse {
    border-radius: 50%;
    width: 2px;
    height: 2px;
    background-color: var(--neutrals-400);
} */
