#signup-name {
    padding-top: 200px;
}

#signup-email {
    padding-bottom: 25px;
}

#signup-password {
    padding-bottom: 35px;
}
